






































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import configProvider from "@common/services/configProvider";
import XAutocomplete from "@common/modules/vuetifyx/components/XAutocomplete.vue";
import GlobalErrorSnackBar from "@/components/GlobalErrorSnackBar.vue";

export default Vue.extend({
  components: {XAutocomplete, GlobalErrorSnackBar},
  data() {
    return {
      valid: false,
      loading: false,
      error: "",
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      rulePhoneNumber: (value) => !!this.isValidPhone(value) || "Phone Number invalid",
      isValidPhone: phone => /([|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/g.test(phone),
      logoUrl: configProvider.get("LOGO_URL"),
      tenantInputOptions: {
        attrs: {
          label: "CPO (*)",
          prependIcon: "mdi-file-tree-outline",
          color: "primary",
          rules: [this.ruleRequired],
          "item-text": "name",
          "item-value": "_id",
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const {items} = await coreApiClient.call("tenants", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                orderBy: "name",
                orderType: "asc",
                filters: [
                  {
                    key: "_id",
                    operator: "equal_to",
                    value,
                  },
                ],
              }),
            });
            return items;
          },
        },
      },
      data: {},
    };
  },
  methods: {
    isOnlyNumber(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    async submit() {
      this.error = "";
      this.loading = true;
      try {
        const item = await coreApiClient.callRaw("auth", "signup", undefined, this.data);
        if (item) {
          alert("Signup Successfully");
          this.$router.replace("/login");
        }
      } catch (err) {
        const theErr = err as any;
        if (theErr.response?.body?.message) {
          this.error = theErr.response.body.message;
        } else {
          this.error = err;
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
